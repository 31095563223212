import React from "react";
import mobileIcon from "../images/telefon-white.svg";

const WorkTime = () => {
  return (
      <div className="work-time">
          <p className="work-main">DELOVNI ČAS:</p>
          <p className="work">ODPRTO</p>
          <p className="work-main">24.12.2024: 8:00 - 13:00</p>
          <p className="work-main">31.12.2024: 8:00 - 12:00</p>
          <p className="work-main">PON - PET: 8:00 - 19:00</p>
          <p className="work-main">SOB: 8:00 - 12:00</p>
          <p className="work">ZAPRTO </p>
          <p className="work-main">NEDELJA IN PRAZNIKI</p>

          <span className="mobile-red">
        <img src={mobileIcon} alt="mobile icon" className="mobile-icon"/>
        <span className="mobile-red-text">
          <a className="telefonska" href="tel:01 565 7555">
          01 565 7555
          </a>
        </span>
      </span>
      </div>
  );
};

export default WorkTime;
